<template>
  <v-container>
    <v-app-bar app>
      <v-container class="toolbar">
        <h3 class="toolbar__title">Sistema de Mesa de Partes Virtual</h3>
        <div>
          <v-img
            :src="require('../assets/escudo_estique.png')"
            contain
            height="50"
            width="400"
          />
        </div>
      </v-container>
    </v-app-bar>
    <h1
      style="
        display: flex;
        justify-content: center;
        font-size: 16px;
        text-transform: uppercase;
      "
    >
      Consulta tu trámite
    </h1>
    <v-card style="display: flex; justify-content: center; margin-top: 10px">
      <v-flex sm8>
        <v-layout row wrap>
          <v-flex sm4 style="margin-left: 30px">
            <h4 style="font-size: 12px">Año</h4>
            <el-select v-model="anio" style="width: 300px">
              <el-option
                v-for="item in years"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </v-flex>
          <v-flex sm4 style="margin-left: 30px">
            <h4 class="label" style="font-size: 12px">Número</h4>
            <el-input style="width: 300px" v-model="number"> </el-input>
          </v-flex>
          <vue-recaptcha
            sitekey="6Ld6MpAdAAAAAEteIAcD1PttjWvKmp6UlOS2vvnB"
            @verify="onVerify"
            :loadRecaptchaScript="true"
            style="margin-top: 20px; margin-bottom: 20px; margin-left: 30px"
          ></vue-recaptcha>
          <v-btn
            :disabled="!form.robot"
            style="
              width: 300px;
              margin-bottom: 20px;
              margin-top: 30px;
              margin-left: 20px;
              font-size: 12px;
              background:#047A01;
              color: white;
            "
            @click="searchDocument"
            :loading="isSearching"
          >
            Buscar Documento
          </v-btn>
          <!-- </v-flex> -->
        </v-layout>
      </v-flex>
    </v-card>
    <v-card
      v-if="documentState.length > 0"
      style="margin-top: 10px; border: 3px solid #b3d4fc"
    >
      <div v-for="(consulta, index) in documentState" :key="index">
        <div v-for="(document, indice) in consulta.documentos" :key="indice">
          <div
            style="
              display: flex;
              justify-content: center;
              padding: 10px;
              font-size: 12px;
            "
          >
            <h2 style="font-size: 14px">Estado de un documento</h2>
          </div>
          <div
            style="
              margin: 10px 20px 10px 20px;
              font-size: 20px;
              background: #e0e0e0;
              padding: 6px;
              font-size: 12px;
            "
          >
            Detalles del documento
          </div>
          <div style="display: flex; margin-top: 5px">
            <div
              style="
                width: 300px;
                margin-left: 20px;
                font-weight: bold;
                font-size: 12px;
              "
            >
              Número de registro:
            </div>
            <div>
              <p style="font-size: 12px">{{ document.titulo }}</p>
            </div>
          </div>
          <div style="display: flex; margin-top: 5px">
            <div
              style="
                width: 300px;
                margin-left: 20px;
                font-weight: bold;
                font-size: 12px;
              "
            >
              Documento:
            </div>
            <div>
              <p style="font-size: 12px">{{ document.cud }}</p>
            </div>
          </div>
          <div style="display: flex; margin-top: 5px">
            <div
              style="
                width: 300px;
                margin-left: 20px;
                font-weight: bold;
                font-size: 12px;
              "
            >
              Fecha:
            </div>
            <div>
              <p style="font-size: 12px">
                {{ formatDate(document.fecha_creacion) }}
              </p>
            </div>
          </div>
          <div style="display: flex; margin-top: 5px">
            <div
              style="
                width: 300px;
                margin-left: 20px;
                font-weight: bold;
                font-size: 12px;
              "
            >
              Asunto:
            </div>
            <div>
              <p style="font-size: 12px">{{ document.asunto }}</p>
            </div>
          </div>

          <!-- <div style="display: flex; margin-top: 5px; font-size: 12px">
            <div
              style="
                width: 300px;
                margin-left: 20px;
                font-weight: bold;
                font-size: 12px;
              "
            >
              Presentado Por:
            </div>
            <p>{{ consulta.generado_por }}</p>
          </div> -->
          <!-- <div style="display: flex; margin-top: 10px">
            <div
              style="
                width: 300px;
                margin-left: 20px;
                font-weight: bold;
                font-size: 12px;
              "
            >
              Registrado Por:
            </div>
            <div>
              <p style="font-size: 12px">{{ document.generado_por }}</p>
              <p style="margin-top: -10px; font-size: 12px">
                Mesa de partes central
              </p>
            </div>

            <hr style="margin: 10px 20px 10px 20px" />
          </div> -->
          <div
            style="
              margin: 10px 20px 10px 20px;
              font-size: 20px;
              background: #e0e0e0;
              padding: 5px;
              font-size: 12px;
            "
          >
            Historial
          </div>
          <table
            class="table__history"
            v-if="document.transacciones.length > 0"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Fecha</th>
                <th>Estado</th>
                <th>Descripción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in document.transacciones" :key="index">
                <th>{{ index + 1 }}</th>
                <td>{{ formatDate(item.fecha_creacion) }}</td>
                <td>{{ formatTransaction(item.tipo_transaccion) }}</td>
                <td>{{ createText(item) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import axios from "../config/axios";

import VueRecaptcha from "vue-recaptcha";
export default {
  data() {
    return {
      isSearching: false,
      years: [],
      anio: new Date().getFullYear(),
      number: 0,
      documentState: [],
      form: {
        robot: false,
      },
    };
  },
  components: {
    "vue-recaptcha": VueRecaptcha,
  },

  created() {
    const years = this.getYears();
    this.years = years;
  },
  methods: {
    getYears() {
      const date = new Date();
      const year = date.getFullYear();
      const years = [year-3,year - 2, year - 1, year];
      return years;
    },
    async searchDocument() {
      try {
        this.isSearching = true;
        const data = {
          anio: this.anio,
          numero: this.number,
        };
        const res = await axios.post("/buscar_expediente", data);
        this.documentState = res.data.data;
        this.isSearching = false;

        if (res.data.data.length === 0) {
          this.$message.error("No se encuentra ese nùmero de tramite");
        }
      } catch (error) {
        this.isSearching = false;
        this.$message.error("No se encuentra ese nùmero de tramite");
      }
    },

    onVerify: function (response) {
      if (response) this.form.robot = true;
    },
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date);
      let day = d.getUTCDate();
      let month = d.getUTCMonth() + 1;
      let year = d.getUTCFullYear();
      return `${day}/${month}/${year}`;
    },
    createText(item) {
      if (item.tipo_transaccion === "Generar") {
        return `El documento ha sido GENERADO por ${
          item.remitente_nombre || ""
        } (${item.remitente_cargo})  ${
          item.remitente ? `de ${item.remitente}` : ""
        } `;
      }
      if (item.tipo_transaccion === "Recepcionar") {
        return `El documento ha sido RECEPCIONADO por ${
          item.remitente_nombre || ""
        } ${item.remitente_cargo} de ${item.remitente}`;
      }
      if (item.tipo_transaccion === "Proveido") {
        return `El documento ha sido PROVEIDO por ${
          item.remitente_nombre || ""
        } ${item.remitente_cargo} de ${item.remitente}`;
      }
      if (item.tipo_transaccion === "Derivar") {
        return `El documento ha sido DERIVADO por ${
          item.remitente_nombre || ""
        } ${item.remitente_cargo} de ${item.remitente}`;
      }
      if (item.tipo_transaccion === "Atender") {
        return `El documento ha sido ATENDIDO por ${
          item.remitente_nombre || ""
        } ${item.remitente_cargo} de ${item.remitente}`;
      }
    },
    formatTransaction(transaction) {
      const transactions = {
        Generar: "Generado",
        Derivar: "Derivado",
        Recepcionar: "Recepcionado",
        Archivar: "Archivado",
        Atender: "Atendido",
        Proveido: "Proveido",
      };

      return transactions[transaction];
    },
  },
};
</script>
<style scoped>
h4 {
  margin-top: 10px;
}
.table__history {
  border: 1px solid #000;
  border-collapse: collapse;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
  font-size: 12px;
}
.table__history th,
.table__history td {
  padding: 10px;
  border: 1px solid #000;
}
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
  background: #0f62ac !important;
}

.login--img {
  width: 100%;
}
.label {
  margin-left: 30px;
}
.el-input {
  margin-left: 30px;
}
@media only screen and (max-width: 600px) {
  .label {
    margin-left: 0px;
  }
  .el-input {
    margin-left: 0px;
  }
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toolbar__title {
  text-transform: uppercase;
}

@media only screen and (max-width:700px){
  .toolbar__title{
    display:none;
  }
}
</style>