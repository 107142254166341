import Vue from 'vue'
import Router from 'vue-router'
import Consulta from '@/components/Consulta'

// import Tramite from '@/components/TramiteExterno'
// import Main from '@/components/Main'
// import NoTime from '@/views/NoTime'

Vue.use(Router)
export default new Router({
    routes: [
        {
            path: '/',
            component: Consulta
        },
        // {
        //     path: '/no-disponible',
        //     component: NoTime
        // },
        // {
        //     path: '/tramite',
        //     component: Tramite
        // },
        // {
        //     path: '/consulta',
        //     component: Consulta
        // },
    ],
    linkActiveClass: "active",
    mode: "history"
})